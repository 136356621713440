import NvidiaGIF from './nvidia.gif'
import Description from "./Description";


export default function FinalPage() {
    return (
        <div>
            <div style={{width: '100vw',height: '75vh',overflow: 'hidden',backgroundColor: 'white'}}> 
                <img src={NvidiaGIF} alt="Nvidia GIF" style={{ width: '100%',height: '100%',objectFit: 'contain'}}/>
            </div>
            <div id="div_description">
                <Description />
            </div>
        </div>
    );
}
