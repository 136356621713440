import ExchangeRatesHeatMap from '../Comps/vt_page_exchange_rates_heatmap/Main.js'
import TwitterContent from '../Comps/vt_page_twitter_content_gen/Main.js'
import WeworkDrop from '../Comps/vt_page_wework_drop/Main.js'
import InflationNearUSA from '../Comps/vt_page_inflation_near_usa/Main.js'
import SatEmission from '../Comps/vt_page_sat_emission/Main.js'
import PageChina from '../Comps/vt_page_china_gdp/Main.js'
import JapanCovid from '../Comps/vt_page_japan_covid/Main.js'
import ChinaAnualGrowth from '../Comps/vt_page_china_anual_growth/Main.js'
import ChinaIndiaPopulation from '../Comps/vt_page_china_india_population/Main.js'
import TikTokUserGrowth from '../Comps/vt_page_tiktok_user_growth/Main.js'
import OilMain from '../Comps/vt_page_usa_oil_imports/Main.js'
import CovidTimeline from '../Comps/vt_page_covid_timeline/Main.js'
import FacebookDevicesPage from '../Comps/vt_page_facebook_users/Main.js'
import AppleStock from '../Comps/vt_page_apple_stock_movement/Main.js'
import WealthiestFamilies from '../Comps/vt_page_wealthiest_families/Main.js'
import RussianRuble from '../Comps/vt_page_russian_ruble/Main.js'
import AustraliaTemp from '../Comps/vt_page_australia_temp/Main.js'
import TwitterTimeline from '../Comps/vt_page_twitter_timeline/Main.js'
import TallestBuildings from '../Comps/vt_page_world_tallest_buildings/Main.js'
import BiggestCompanies from '../Comps/vt_page_biggest_companies/Main.js'
import NvidiaGrowth from '../Comps/vt_page_nvidia_company_growth/Main.js'

const list = [
//{ id:1,  path:'/SatEmission' ,name:'Satellite Emission' , component:SatEmission},
{ id:1, category:1,  path:'/BiggestCompanies' ,name:'Global Giants by Market Capitalization' , component:BiggestCompanies},
{ id:5, category:2,  path:'/JapanCovid' ,name:'Japan Covid Deaths' , component:JapanCovid},
{ id:17, category:3,  path:'/TallestBuildings' ,name:'Tallest Buildings' , component:TallestBuildings},
{ id:3, category:4,  path:'/TwitterContent' ,name:'Twitter Users' , component:TwitterContent},
{ id:2, category:5, path: "/ExchangeRatesHeatMap", name: "Exchange Rates HeatMap", component: ExchangeRatesHeatMap},
{ id:4, category:6,  path:'/PageChina' ,name:'China GDP Growth' , component:PageChina},
{ id:6, category:1,  path:'/weworkDrop' ,name:'Wework Drop' , component:WeworkDrop},
{ id:7, category:5,  path:'/InflationNearUSA' ,name:'Inflation Near USA' , component:InflationNearUSA},
{ id:8, category:3,  path:'/ChinaAnualGrowth' ,name:'China Economic Growth' , component:ChinaAnualGrowth},
{ id:9, category:4,  path:'/ChinaIndiaPopulation' ,name:'China India Population' , component:ChinaIndiaPopulation},
{ id:10, category:2,  path:'/TikTokUserGrowth' ,name:'Tik Tok User Growth' , component:TikTokUserGrowth},
{ id:11, category:6,  path:'/USAOilImport' ,name:'USA Oil Import' , component:OilMain},
{ id:12, category:2,  path:'/CovidTimeline' ,name:'Covid Timeline' , component:CovidTimeline},
{ id:13, category:3,  path:'/FacebookConnectivity' ,name:'Facebook Connectivity' , component:FacebookDevicesPage},
{ id:14, category:1,  path:'/AppleStockMovement' ,name:'Apple Stock Movement' , component:AppleStock},
{ id:15, category:4,  path:'/AustraliaTemp' ,name:'Australia Heat Change' , component:AustraliaTemp},
{ id:16, category:6,  path:'/TwitterTimeline' ,name:'Twitter Timeline' , component:TwitterTimeline},
{ id:18, category:5,  path:'/RussianRuble' ,name:'Russian Ruble' , component:RussianRuble},
{ id:19, category:1,  path:'/WealthiesFamilies' ,name:'Wealthiest Families' , component:WealthiestFamilies},
{ id:20, category:2,  path:'/NvidiaGrowth' ,name:'Nvidia Company Growth' , component:NvidiaGrowth},
];

export default list;
