import * as d3 from "d3";
import * as fn from "./simple_funs.js";
import * as fn_txt from "./text_functions.js";
import csvFile from "./data.csv";

export default class Chart {
	constructor(g, rect, t) {
		this.rect = rect;
		this.outerg = g;
		this.g = g.append("g");
		this.dataPoints = 10;
		this.margin = 50;
		this.totalDuration = t;
	}

	async bindData() {
		this.st = [];
		const data = await d3.csv(csvFile);
		this.st = data.map((d) => ({
			x: d["Year"],
			y: Number(d["Downloads"]),
		}));

	}

	add_scale() {
		var st = this.st;

		var height = this.maxHeight;
		var width = this.maxWidth;

		//when giving ycale need to give the minimum otherwise there will be a problem
		var ymin = 0;
		var ymax = d3.max(st, (d) => d.y);

		var xscale = d3
			.scaleBand()
			.domain(st.map((d) => d.x))
			.range([0, width])
			.padding(1);

		var yscale = d3
			.scaleLinear()
			.domain([ymin, ymax])
			.range([height, 0])
			.nice();

		//add the axis
		this.gX = this.g
			.append("g")
			.attr("id", "g_xaxis")
			.call(d3.axisBottom(xscale))
			.call(fn.translate, 0, this.maxHeight)
			.selectAll("text")
			.style("font-size", "8px");

		this.gY = this.g
			.append("g")
			.attr("id", "g_yaxis")
			.call(d3.axisLeft(yscale.nice()))
			.call(fn.translate, 0, 0)
			.selectAll("text")
			.style("font-size", "15px");

		this.xscale = xscale;
		this.yscale = yscale;
	}

	addAnimationScale() {
		var st = this.st;

		var xAxisStartPoint = fn.lineGetStart(this.xAxis)
		var xAxisEndPoint = fn.lineGetEnd(this.xAxis)
		this.yAxisStartPoint = fn.lineGetStart(this.yAxis)
		var yAxisEndPoint = fn.lineGetEnd(this.yAxis)

		//when giving ycale need to give the minimum otherwise there will be a problem
		var ymin = 0;
		var ymax = d3.max(st, (d) => d.y);

		var xscale = d3
			.scaleBand()
			.domain(st.map((d) => d.x))
			.range([xAxisStartPoint[0], xAxisEndPoint[0]])
			.padding(1);

		var yscale = d3
			.scaleLinear()
			.domain([ymin, ymax])
			.range([this.yAxisStartPoint[1], yAxisEndPoint[1]])
			.nice();

		this.xscale = xscale;
		this.yscale = yscale;
	}

	addLines() {
		var st = this.st;
		var xscale = this.xscale;
		var yscale = this.yscale;
		var height = this.yAxisStartPoint[1]


		//this is something similar to x line  ( same as x line but diffent y value)
		var line_three = d3
			.line()
			.x((d) => xscale(d.x))
			.y((d) => height - 100)
			.curve(d3.curveMonotoneX);

		var line_one = d3
			.line()
			.x((d) => xscale(d.x)) // Use the same x-scale for the line
			.y((d) => yscale(d.y)) // Ensure y-values are mapped to the y-scale
			.curve(d3.curveMonotoneX);

		var path_one = this.g
			.append("g")
			.append("path")
			.attr("fill", "none")
			.attr("stroke", "black")
			.attr('opacity',0)
			.attr("d", line_one(st)); // Pass the data to the line generator


		var path_three = this.g
			.append("path")
			.attr("fill", "none")
			.attr("stroke", "black")
			.attr('opacity',0)
			.datum(st)
			.attr("d", line_three);

		this.path_four = this.outerg.select('#Lines')
						.append("path")
						.attr("fill", "none")
						.attr("stroke", "#474747")
						.attr('opacity',0)
						.datum(st)
						.attr("d", line_one(st));
		this.path_one = path_one;
		this.path_three = path_three;
	}

	hideLines() {
		this.path_one.attr("opacity", 0);
		this.path_three.attr("opacity", 0);
	}

	addArea() {
		var st = this.st;
		var xscale = this.xscale;
		var yscale = this.yscale;
		var height = this.maxHeight;
		var width = this.maxWidth;

		var area_cords = d3
			.area()
			.x((d) => xscale(d.x))
			.y0(yscale(0))
			.y1((d) => yscale(d.y))
			.curve(d3.curveMonotoneX);

		var area_one = this.g
			.append("path")
			.attr("fill", "pink")
			.attr("stroke", "none")
			.datum(st)
			.attr("d", area_cords);

		this.area_one = area_one;
	}

	addCountText() {
		var lastValue = this.st[this.st.length - 1]
		this.textCount = fn.add_text(this.g, lastValue.y, this.xscale(lastValue.x), this.yscale(lastValue.y))
		this.textCount.attr('font-size', '25px').attr('fill', 'black').style("font-weight", "bold").attr('id', 'countTxt')
		fn.transform(this.textCount, -20, -10)
	}

	addEndCircle() {
		var lastValue = this.st[this.st.length - 1]

		this.endCircle = fn.add_circle(this.g, this.xscale(lastValue.x), this.yscale(lastValue.y), 5)
		this.endCircle.attr('id', 'endCircle')
	}

	moveArea() {
		var area_one = this.area_one;
		var path_one = this.path_one;
		var path_three = this.path_three;
		var height = this.yAxisStartPoint[1]
		var area_one = this.area_one;
		var pointArray = this.pointArray
		var p =0
		var base = this
		var path_four = this.path_four

		var yscale = this.yscale
		function inverseValue(outputValue) {
			return yscale.invert(outputValue);
		}

		area_one.attr('opacity', 1)
		this.textCount.attr('opacity', 1)
		this.endCircle.attr('opacity', 1)

		//building the list
		var ar = d3
			.range(path_one.node().getTotalLength() + 100)
			.map((d) => ({ point: path_one.node().getPointAtLength(d) }))
			.map((p) => ({ x: p.point.x, y: p.point.y }));

		// This need to generate value at small points  ( for any give x they will generat the y)

		function x_value(ar, val) {
			var bisect = d3.bisector((d) => d.x).left;
			var ind = bisect(ar, val);
			var p = ar[ind];
			return p.x;
		}

		function y_value(ar, xx) {
			var bisect = d3.bisector((d) => d.x).left;
			var ind = bisect(ar, xx);
			var p = ar[ind];
			return p.y;
		}

		function areNumbersClose(num1, num2, tolerance) {
			return Math.abs(num1 - num2) <= tolerance;
		  }

		//This need this will be the x,y values that will be used to animate the are
		var mt = [[x_value(ar, 0), y_value(ar, 0)]];
		var mt1 = [[x_value(ar, 0), y_value(ar, 0)]];

		//running the aniamation
		var textCount = this.textCount
		var endCircle = this.endCircle

		area_one
			.transition()
			.ease(d3.easeLinear)
			.duration(this.totalDuration)
			.tween("area_change", function () {
				var path_three_lenght = path_three.node().getTotalLength();
				var r = d3.interpolate(0, path_three_lenght);
				return function (t) {
					//take the value at t of the line and get the corresponing x and y values
					var val = r(t);
					var nx = path_three.node().getPointAtLength(val).x;
					var py = y_value(ar, nx);
					var px = x_value(ar, nx);
					var text = inverseValue(py).toFixed(0)

					if(areNumbersClose(nx, pointArray[p], 2)){
						base.animateLabel(2000,p)
						p++;
					 }

					mt.push([px, py]);

					textCount.attr('x', px - 60).attr('y', py - 15).text(text + ' Million')
					endCircle.attr('cx', px).attr('cy', py)

					d3.select(this)
						.datum(mt)
						.attr("fill", "#808080")
						.attr("fil-opacity", 0.1)
						.attr("stroke", "none")
						.attr(
							"d",
							d3
								.area()
								.x((d) => d[0])
								.y0(height)
								.y1((d) => d[1])
								.curve(d3.curveMonotoneX)
						);
				};
			});

		path_four
			.transition()
			.ease(d3.easeLinear)
			.duration(this.totalDuration)
			.tween("move_line", function () {
				var path_three_lenght = path_three.node().getTotalLength();
				var r = d3.interpolate(0, path_three_lenght);
				return function (t) {
					var val = r(t);
					var nx = path_three.node().getPointAtLength(val).x;
					var py = y_value(ar, nx);
					var px = x_value(ar, nx);
				
					mt1.push([px, py]);

					d3.select(this)
						.datum(mt1)
						//.attr("fill", "#808080")
						.attr('opacity',1)
						.attr("stroke-width", "3")
						.attr(
							"d",
							d3.line()
							  .x((d) => d[0])
							  .y((d) => d[1])
							  .curve(d3.curveMonotoneX)
						);
				};
			});
	}


	async animateLabel(t , i){
		var n = i+1
		var point = this.points.select('#Point_' + n)
		var group = this.labels.select('#Details_'+ n)
		var drawLine = group.select('#D' + n + '_Line2')
		var line = group.select('#D' + n + '_Line1')
		var topic = group.select('#Topic_' + n).selectAll('tspan')
		

		point.attr('opacity',1)
		drawLine.attr('opacity',1)
		fn.draw_path(drawLine,200)
		line.transition().delay(200).attr('opacity',1)
		.on('end', async () => {
			var topicTime = 500 / topic.size()
			for (const text of topic.nodes()) {
				const tspan = d3.select(text)
				tspan.text(tspan.text().replace(/&#10;/g, ""));
				tspan.text(tspan.text().replace(/&#39;/g, "'"));
				tspan.text(tspan.text().replace(/&#x2019;/g, "'"));
				tspan.attr('opacity', 1);
				await this.wrapper(fn_txt.typeTextWithDuration, topicTime, tspan);
			}
			var desc = group.select('#Discription_' + n).selectAll('tspan')
			var timeForOne = (t - 400) / desc.size();

			// Sequentially process each text element
			for (const text of desc.nodes()) {
				const tspan = d3.select(text);
				tspan.text(tspan.text().replace(/&#xa0;/g, " "));
				tspan.text(tspan.text().replace(/&#39;/g, "'"));
				tspan.text(tspan.text().replace(/&#x2019;/g, "'"));
				tspan.attr('opacity', 1);
				await this.wrapper(this.textLine = fn_txt.typeTextWithDuration, timeForOne, tspan);
			}
		});
	}

	removRect() {
		this.rect.attr("height", 0).attr("width", 0);
	}

	adjustInngerG() {
		this.outerg.call(fn.translate, this.x, this.y);
	}

	initiate() {
		this.length = this.data.length;
	}

	calculateMinMaxInData() {
		this.xmax = d3.max(this.data, (d) => d.x);
		this.ymax = d3.max(this.data, (d) => d.y);
	}

	adjustMargin() {
		this.g.call(fn.translate, this.margin + this.margin / 2, this.margin);
	}

	display_header() {
		console.log("header willl display");
	}

	getRectangelInfo() {
		this.x = parseFloat(this.rect.attr("x"));
		this.y = parseFloat(this.rect.attr("y"));
		this.rectFillColor = this.rect.attr("fill");
	}

	calculateMaxMinValues() {
		this.maxHeight = parseFloat(this.rect.attr("height")) - this.margin * 2;
		this.maxWidth = parseFloat(this.rect.attr("width")) - this.margin * 2;
		this.barWidth = (this.maxWidth / this.dataPoints) * 0.8;
	}

	get_random_data() {
		this.st = [];
		for (let i = 0; i <= this.dataPoints; i++) {
			var randomValue =
				Math.floor(Math.random() * ((100 - 10 + 1) / 10)) * 10 + 10;
			this.st.push([i, randomValue]);
		}
		console.log(this.st);
	}

	wrapper(f, t, a) {
		return new Promise((res, rej) => {
			f.call(this, t, a);
			setTimeout(() => {
				res("done");
			}, t);
		});
	}

	async seq() {
		await this.wrapper(this.getRectangelInfo, 10);
		await this.wrapper(this.adjustInngerG, 10);
		await this.wrapper(this.calculateMaxMinValues, 10);
		await this.wrapper(this.adjustMargin, 10);
		//await this.wrapper(this.get_random_data, 10)
		await this.wrapper(this.bindData, 10);
		await this.wrapper(this.initiate, 10);
		await this.wrapper(this.calculateMinMaxInData, 10);
		await this.wrapper(this.removRect, 10);
		//await this.wrapper(this.sampleDataBuild, 10)
		await this.wrapper(this.add_scale, 10);
		await this.wrapper(this.addLines, 10);
		await this.wrapper(this.hideLines, 10);
		await this.wrapper(this.addArea, 10);
		await this.wrapper(this.moveArea, 10);
		await this.animate(100, 0);
	}

	async build() {
		await this.wrapper(this.getRectangelInfo, 10);
		await this.wrapper(this.adjustInngerG, 10);
		await this.wrapper(this.calculateMaxMinValues, 10);
		await this.wrapper(this.adjustMargin, 10);
		await this.wrapper(this.bindData, 100);
		//await this.wrapper(this.initiate, 10);
		//await this.wrapper(this.calculateMinMaxInData, 10);
		await this.wrapper(this.removRect, 10);
		await this.wrapper(this.add_scale, 10);
		//await this.wrapper(this.addLines, 10);
		//await this.wrapper(this.hideLines, 10);
		await this.wrapper(this.addArea, 10);
		await this.wrapper(this.addCountText, 10);
		await this.wrapper(this.addEndCircle, 10);
		//await this.wrapper(this.moveArea, 10);
	}

	stop(){
		this.area_one.interrupt()
		this.path_four.interrupt()
		this.path_four.attr('opacity',0)
	}

	reset(){
		this.area_one.attr('opacity',0)
		this.points.selectAll('path').attr('opacity',0)
		this.labels.selectAll('tspan').attr('opacity',0)
		this.labels.selectAll('path').attr('opacity',0)
		this.textCount.attr('opacity',0)
		this.endCircle.attr('opacity',0)	
	}

	extract() {
		this.area_one = this.outerg.select('#LinePath')
		this.yAxis = this.outerg.select('#g_yaxis').select('path')
		this.xAxis = this.outerg.select('#g_xaxis').select('path')
		this.textCount = this.outerg.select('#CountTxt').select('tspan')
		this.endCircle = this.outerg.select('#endCircle')
		this.points = this.outerg.select('#Points')
		this.labels = this.outerg.select('#Details')
		
		this.area_one.attr('opacity',0)
		this.points.selectAll('path').attr('opacity',0)
		this.labels.selectAll('tspan').attr('opacity',0)
		this.labels.selectAll('path').attr('opacity',0)

		this.hideChart()
		this.buildPointArray()
	}

	buildPointArray(){
		this.pointArray = []
		for(var i = 1; i < 5; i++){
			const p = this.points.select('#Point_'+i).node().getPointAtLength(0)
			this.pointArray.push(p.x)
		}
	}

	hideChart() {
		this.outerg.select('#area').attr('opacity', 0)
		this.textCount.attr('opacity', 0)
		this.endCircle.attr('opacity', 0)
	}

	async seq() {
		await this.wrapper(this.extract, 10);
		await this.wrapper(this.bindData, 500);
		await this.wrapper(this.addAnimationScale, 10);
		await this.wrapper(this.addLines, 10);
		
	}

	move(){
		this.moveArea()
	}
}
