import * as d3 from "d3";
import * as fn from "./simple_funs.js";
import * as fn_txt from "../vt_gen_functions/text_functions.js";
import * as fn_move from "./move_functions.js";
import csvFile from "./data.csv";

export default class Chart {
  constructor(g, rect, t) {
    this.rect = rect;
    this.outerg = g;
    this.g = g.append("g");
    this.dataPoints = 10;
    this.margin = 50;
    this.data = [];
    this.totalDuration = t;
    this.rect.attr("opacity", 0);
    this.circleRadius = 5;
    this.lblFontSize = 20;
    this.runState = true
  }

  async bindData() {
    this.st = [];
    const data = await d3.csv(csvFile);
    this.st = data.map((d) => ({ x: new Date(d["x"]), y: Number(d["y"]) }));
    this.data = this.st;

  }

  addScale() {

    var st = this.st;

    var height = this.maxHeight;
    var width = this.maxWidth;

    //when giving ycale need to give the minimum otherwise there will be a problem
    var ymin = 0;
    var ymax = d3.max(st, (d) => d.y);


    this.xscale = d3
      .scaleTime()
      .domain(d3.extent(st, (d) => d.x))
      .range([0, width]);



    this.yscale = d3
      .scaleLinear()
      .domain([ymin, ymax])
      .range([100, 0])
      .nice();

    this.invYscale = d3.scaleLinear().domain([height, 0]).range([ymin, ymax])

    this.gX = this.g
      .append("g")
      .attr('id', 'g_xaxis')
      .call(d3.axisBottom(this.xscale))//.ticks(d3.timeYear.every(2)))
      .call(fn.translate, 0, this.maxHeight)
      .selectAll("text")
      .style("font-size", "15px");

    this.gY = this.g
      .append("g")
      .attr('id', 'g_yaxis')
      .call(d3.axisLeft(this.yscale.nice()))
      .selectAll("text")
      .style("font-size", "15px");


  }

  addLines() {
    var st = this.st;
    var xscale = this.xscale;
    var yscale = this.yscale;
    var height = this.maxHeight;
    var width = this.maxWidth;

    var line_one = d3
      .line()
      .x((d) => xscale(d.x))
      .y((d) => yscale(d.y))
    //.curve(d3.curveMonotoneX);
    this.path_one = this.g
      .append("g")
      .append("path")
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 3)
      .datum(st)
      .attr("d", line_one);
  }

  adjustInngerG() {
    this.outerg.call(fn.translate, this.x, this.y);
  }

  initiate() {
    this.length = this.data.length;
  }

  calculateMinMaxInData() {
    this.xmax = d3.max(this.data, (d) => d.x);
    this.ymax = d3.max(this.data, (d) => d.y);
  }

  adjustMargin() {
    this.g.call(fn.translate, this.margin, this.margin);
  }

  getRectangelInfo() {
    console.log('rect', this.rect)
    this.x = parseFloat(this.rect.attr("x"));
    this.y = parseFloat(this.rect.attr("y"));
    this.rectFillColor = this.rect.attr("fill");
  }

  calculateMaxMinValues() {
    this.maxHeight = parseFloat(this.rect.attr("height")) - this.margin * 2;
    this.maxWidth = parseFloat(this.rect.attr("width")) - this.margin * 2;
    this.barWidth = (this.maxWidth / this.dataPoints) * 0.8;
  }

  getRandomData() {
    this.data = [];
    for (let i = 0; i <= this.dataPoints; i++) {
      var randomValue =
        Math.floor(Math.random() * ((100 - 10 + 1) / 10)) * 10 + 10;
      this.data.push({ x: i, y: randomValue });
    }
    this.st = this.data.map((d) => [Number(d["x"]), Number(d["y"])]);
  }

  addLabels() {
    this.labels = this.g
      .append("g")
      .attr("id", "labels")
      .selectAll("text")
      .data(this.data)
      .enter()
      .append("text")
      .attr("id", (d, i) => "text" + i)
      .attr("x", (d, i) => this.xscale(d.x) - 10)
      .attr("y", (d, i) => this.yscale(d.y) + 30)
      .attr("fill", "black")
      .attr("font-size", this.lblFontSize)
      .text((d, i) => d.x + "," + d.y)
      .attr("opacity", 0);
  }

  addCircles() {
    this.circle = this.g
      .append("g")
      .attr("id", "circles")
      .selectAll("circle")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("id", (d, i) => "circle" + i)
      .attr("cx", (d, i) => this.xscale(d.x))
      .attr("cy", (d, i) => this.yscale(d.y))
      .attr("fill", "green")
      .attr("r", this.circleRadius);
  }

  addExtractedCircles() {
    this.circle = this.g
      .append("g")
      .attr("id", "circles")
      .selectAll("circle")
      .data(this.data)
      .enter()
      .append("circle")
      .attr("id", (d, i) => "circle" + i)
      .attr("cx", (d, i) => this.xscale(d.x))
      .attr("cy", (d, i) => this.yscale(d.y))
      .attr("fill", (d, i) => this.circleArray[i].color)
      .attr("opacity", 1)
      .attr("r", 12);
  }

  addEndCircle() {
    var x = this.circles.select('#circle0').attr('x')
    var y = this.circles.select('#circle0').attr('y')
    this.endCircle = fn
      .add_circle2(this.outerg, y, x, 10)
      .attr("fill", "black");
  }

  async animateOneLabel(durationForOneLbl, i) {
    var g = this.labels.select('#label_' + i)
    g.select('rect').attr('opacity', 1)
    if (i !== this.circles.selectAll('circle').size()) {
      const lblY = g.select('#text' + i).select('text').select('tspan')
      lblY.transition().duration(100).attr('opacity', 1)
    }

    const topic = g.select('#Topic' + i).select('tspan');
    topic.attr('opacity', 1)
    await this.wrapper(this.topic = fn_txt.typeTextWithDuration, 100, topic)

    const desc = g.select("#descGroup" + i).selectAll('text');
    const durationForOneLine = (durationForOneLbl - 100) / desc.size();

    for (const text of desc.nodes()) {
      const tspan = d3.select(text).select('tspan');     
      tspan.text(tspan.text().replace(/&#39;/g, "'"));
      tspan.text(tspan.text().replace(/&#x2019;/g, "'"));
      tspan.attr('opacity', 1)
      await this.wrapper(this.textLine = fn_txt.typeTextWithDuration, durationForOneLine, tspan);
    }
  }

  async animateLabels(totalDuration, i) {

    var durationForOneLbl = totalDuration / this.circles.selectAll('circle').size();
    if (i <= this.circles.selectAll('circle').size() && this.runState) {
      await this.wrapper(this.animateOneLabel, durationForOneLbl, i);
      i += 1;
      await this.wrapper(this.animateLabels, totalDuration, i)
    }
  }

  animateOneCircle(durationForOneCircle, i) {

    var g = this.circles
    g.select("#circle" + i).call(
      fn.anim,
      durationForOneCircle,
      "r",
      this.circleRadius
    );
  }

  async animateCircles(totalDuration, i) {
    var durationForOneCircle = totalDuration / (this.circles.selectAll('circle').size() + 1);
    if (i < this.circles.selectAll('circle').size() && this.runState) {
      await this.wrapper(this.animateOneCircle, durationForOneCircle, i);
      i += 1;
      await this.animateCircles(totalDuration, i);
    }
  }

  wrapper(f, t, a) {
    return new Promise((res, rej) => {
      f.call(this, t, a);
      setTimeout(() => {
        res("done");
      }, t);
    });
  }

  removRect() {
    this.rect.attr("height", 0).attr("width", 0);
  }

  removeBarsLabels() {
    this.circles.selectAll('circle').attr('r', 0)
    this.labels.selectAll('tspan').attr('opacity', 0)
    this.labels.selectAll('rect').attr('opacity', 0)
    this.path_one.attr("opacity", 0);
    this.text.attr('opacity', 0)
    this.endCircle.attr('opacity', 0)
  }

  addMoveText() {
    this.text = fn.add_text(this.outerg, "0", 0, 0)
    this.text.attr('font-size', '25px').attr('fill', 'black').style("font-weight", "bold")
  }

  animatePath(totalDuration) {
    this.path_one
      .attr('opacity', 1)
      .call(fn.draw_path, totalDuration)
      .transition().duration(totalDuration).ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0)
      .call(fn_move.move_with_line, this.endCircle, 0, 0)
      .call(this.moveText, this.text, this.invYscale)

  }

  async animate(totalDuration, i) {
    this.timeForPath = (totalDuration /2)
    await this.wrapper(this.animatePath,this.timeForPath );
    if (this.runState) {
      this.animateLabels(totalDuration / 2, i + 1)
      this.animateCircles(totalDuration / 2, i)
    }
    
  }

  moveText(selection, object, yscale) {
    selection.tween('chng', function () {
      var l = selection.node().getTotalLength();
      var r = d3.interpolate(0, l);

      return function (t) {
        const point = selection.node().getPointAtLength(r(t));
        const text = (yscale(point.y) - 0.59).toFixed(1);
        fn.transform(object, point.x - 5, point.y - 15);
        object.text(text + " Billion")
      };
    });
  }

  async addScaleForAnimation() {
    this.yAxisStartPoint = fn.lineGetStart(this.yAxis)
    this.yAxisEndPoint = fn.lineGetEnd(this.yAxis)
    this.maxHeight = this.yAxis.node().getTotalLength()
    await this.wrapper(this.bindData, 100);
    const st = this.st


    this.invYscale = d3
      .scaleLinear()
      .domain([this.yAxisStartPoint[1], this.yAxisEndPoint[1]])
      .range([0, 50])
  }

  extract() {
    this.labels = this.outerg.select('#labels')
    this.circles = this.outerg.select('#circles')
    this.circleRadius = this.circles.selectAll('circle').attr('r')
    this.path_one = this.outerg.select('#chart_line')
    this.yAxis = this.outerg.select('#yAxis')
    this.xAxis = this.outerg.select('#xAxis')


    this.addEndCircle()
    this.addMoveText()
    //this.removeBarsLabels()
    this.addScaleForAnimation()
  }

  async move() {
    this.removeBarsLabels()
    this.text.attr('opacity', 1)
    this.endCircle.attr('opacity', 1)
    await this.wrapper(this.animate, this.totalDuration, 0);
  }

  changeDuration(value) {
    this.totalDuration = value
    this.timeForPath = (value /2)
}

  stop() {
    this.runState = false
    this.circles.selectAll('cicle').interrupt()
    this.path_one.interrupt()
    //this.removeBarsLabels()
  }

  reset() {
    setTimeout(() => {
      this.runState = true
      console.log(this.runState)
    }, this.timeForPath);
  }

  async seq() {
    await this.wrapper(this.getRectangelInfo, 10);
    await this.wrapper(this.adjustInngerG, 10);
    await this.wrapper(this.calculateMaxMinValues, 10);
    await this.wrapper(this.adjustMargin, 10);
    await this.wrapper(this.bindData, 10);
    await this.wrapper(this.initiate, 10);
    await this.wrapper(this.calculateMinMaxInData, 10);
    await this.wrapper(this.removRect, 10);
    await this.wrapper(this.addScale, 10);
    await this.wrapper(this.addLines, 10);
    await this.wrapper(this.addCircles, 10);
    await this.wrapper(this.addEndCircle, 10);
    await this.wrapper(this.addLabels, 10);
    await this.wrapper(this.animate, this.totalDuration, 0);
    //await this.wrapper(this.moveEndCircle, 10)
  }

  async build() {
    await this.wrapper(this.getRectangelInfo, 10);
    await this.wrapper(this.adjustInngerG, 10);
    await this.wrapper(this.calculateMaxMinValues, 10);
    await this.wrapper(this.adjustMargin, 10);
    await this.wrapper(this.bindData, 10);
    await this.wrapper(this.initiate, 10);
    await this.wrapper(this.calculateMinMaxInData, 10);
    await this.wrapper(this.removRect, 10);
    await this.wrapper(this.addScale, 10);
    await this.wrapper(this.addLines, 10);
    //this.addEndCircle()
    await this.wrapper(this.addMoveText, 10);
    await this.wrapper(this.animate, this.totalDuration, 0);
    //await this.wrapper(this.addCircles, 10);
    // await this.wrapper(this.addEndCircle, 10);
    // await this.wrapper(this.addLabels, 10);
  }
}
