import React from 'react';
import { Container, Box, Stack, Typography, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import MainLayout from './Layouts/MainLayout';
import Subscription from './Subscription';

import economyIcon from './images/icons/economy.png';
import entertaintmentIcon from './images/icons/entertaintment.png';
import socialMediaIcon from './images/icons/social-media.png';
import sportsIcon from './images/icons/sports.png';
import technologyIcon from './images/icons/technology.png';
import TestHomePageAnimation from '../vt_page_home_page_animation/Main.js'


function Home() {
    return (
        <div>
            <MainLayout>

                {/* Main Banner / Graph */}
                <Container disableGutters maxWidth={false}>
                    <Box sx={{ bgcolor: 'black', height: { xs: '90vh', md: '60vh' } }} >
			<div>
			<TestHomePageAnimation/>
	    </div>
                        {/* Add the visualization component here */
			}
                    </Box>
                </Container>
                {/* End Main Banner / Graph */}


                {/* Title section */}
                <Container disableGutters maxWidth={false}>
                    <Stack spacing={2} mt={8} mb={8}>
                        <Box sx={{ width: { xs: '95%', md: '90%', lg: '70%' }, margin: 'auto !important' }}>
                            <Typography variant="h1" className="headerxl" gutterBottom>
                                See the Story in Every Data Point.
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant="h2" className="header-3" gutterBottom>
                                A beautiful visual collection of everyday data
                            </Typography>
                        </Box>
                        <Box>
                            <Stack direction="row" spacing={2} sx={{ justifyContent: 'center' }}>
                                <Link to="/collection" className='button-outline'>
                                    View Collection
                                </Link>
                                <div style={{ marginTop: '8px'}}>
                                    <Subscription/>
                                </div>
                                
                            </Stack>
                        </Box>
                    </Stack>
                </Container>
                {/* End title section */}

                {/* Services */}
                <Container maxWidth="xl" sx={{ mt: 16, mb: 8 }}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box sx={{
                                textAlign: 'left',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                            }}>
                                <Typography variant="h3" className="header-3" gutterBottom>
                                Free Charts
                                </Typography>
                                <Typography variant="body" className="body-text" gutterBottom mb={4}>
                                If you're interested in trying our services, we are happy to create few free animated charts based on your requirements. Just have a chat with us.
                                </Typography>
                                {/* <Link className='button-primary'>
                                    Get in Touch
                                </Link> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box sx={{
                                textAlign: 'left',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                            }}>
                                <Typography variant="h3" className="header-3" gutterBottom>
                                Purchase Content
                                </Typography>
                                <Typography variant="body" className="body-text" gutterBottom mb={4}>
                                If you like any of our existing artwork, you can buy it, and we’re happy to make modifications to fit your needs.                                </Typography>
                                {/* <Link className='button-primary'>
                                    Get in Touch
                                </Link> */}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <Box sx={{
                                textAlign: 'left',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                            }}>
                                <Typography variant="h3" className="header-3" gutterBottom>
                                Customized Charts
                                </Typography>
                                <Typography variant="body" className="body-text" gutterBottom mb={4}>
                                If you have an idea and want to create an infographic with custom animations, we can build it for you.                                </Typography>
                                {/* <Link className='button-primary'>
                                    Get in Touch
                                </Link> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                {/* End Services */}

                {/* Categories */}
                <Container maxWidth="xl" sx={{ mt: 16, mb: 8 }}>
                    <Grid container spacing={8} sx={{display:'flex',
                            justifyContent: 'center'}}>
                        <Grid item xs={12} md={6} lg={6}>
                            <Box sx={{
                                textAlign: 'center',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                            }}>

                                <Typography variant="h2" className="header-2" gutterBottom>
                                    Categories
                                </Typography>
                                <Typography variant="body" className="body-text" gutterBottom mb={4}>
                                    Discover visualizations across a variety of fields, including Economy, Entertainment, Social Media, Sports, and Technology. Dive into data insights that matter to you, organized by category for easy access and exploration.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={8} sx={{ 
                            display:'flex',
                            justifyContent: 'center',
                             }}>
                        <Grid item xs={4} md={3} lg={2}>
                            <Link to="/collection" className="category-link">
                                <img src={economyIcon} />
                                <p>Economy</p>
                            </Link>
                        </Grid>
                        <Grid item xs={4} md={3} lg={2}>
                            <Link to="/collection" className="category-link">
                                <img src={entertaintmentIcon} />
                                <p>Entertainment</p>
                            </Link>
                        </Grid>
                        <Grid item xs={4} md={3} lg={2}>
                            <Link to="/collection" className="category-link">
                                <img src={socialMediaIcon} />
                                <p>Social Media</p>
                            </Link>
                        </Grid>
                        <Grid item xs={4} md={3} lg={2}>
                            <Link to="/collection" className="category-link">
                                <img src={sportsIcon} />
                                <p>Sports</p>
                            </Link>
                        </Grid>
                        <Grid item xs={4} md={3} lg={2}>
                            <Link to="/collection" className="category-link">
                                <img src={technologyIcon} />
                                <p>Technology</p>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
                {/* End Categories */}

            </MainLayout>
        </div >
    )
}

export default Home;
